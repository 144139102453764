<template>
  <section class="mt-3 match-height stores-grid">
    <b-card
      v-for="store in stores"
      class="mt-1 text-center store-card p-1"
      :key="store.id"
      no-body
      @click="navigateToOnlineStoreView(store.id)"
    >
      <div class="store-card-body">
        <div class="d-flex justify-content-center mt--5 mb-1 position-relative">
          <span>
            <b-avatar
              :src="`${store.logo}`"
              size="5rem"
              class="cursor-pointer"
            />
          </span>
        </div>
        <h3 class="store-name">
          {{ store.name }}
        </h3>
        <div class="d-flex align-items-center justify-content-center">
          <b-badge variant="light-secondary">
            <feather-icon icon="BoxIcon" class="mr-1" />
            {{ store.store_products_count }}
          </b-badge>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {},
  data() {
    return {
      onlineStoreView: "e-commerce",
      userData: JSON.parse(localStorage.getItem("userData")),
      stores: null,
    };
  },
  computed: {
    ...mapGetters("establishments", ["establishmentsOnlineStores"]),
  },
  mounted() {
    this.emptyCart();
    this.fetchOnlineStores({
      by_store_type: this.$route.params.store_type,
      by_category: "online",

      meta: {
        pagination: {
          page: 1,
          per_page: 100000,
        },
      },
    }).then((response) => {
      this.stores = response.data;
    });
  },

  methods: {
    ...mapActions("establishments", ["fetchEstablishments"]),
    ...mapActions("stores", ["fetchOnlineStores"]),
    ...mapMutations("establishments", [
      "setOnlineStores",
      "setEmptyOnlineStores",
    ]),
    ...mapActions("pos", ["emptyCart"]),

    getCategoryNameInSpanish(name) {
      // Define a mapping of English to Spanish category names
      const categoryTranslations = {
        corner_shop: "abarrotes",
        school_cafeteria: "escuela",
        flower_store: "florería",
        book_store: "librería",
        hardware_store: "ferretería",
        tortilleria: "tortillería",
        coffee_shop: "cafetería",
        clothing_store: "ropa",
        water_purifier: "agua purificada",
        corporate_education: "cursos",
        cleaning_hardware: "productos de limpieza",
        other: "otras",
      };

      // Return the Spanish translation or the original name if not found
      return categoryTranslations[name] || name;
    },

    navigateToOnlineStoreView(storeId) {
      const fromStoreId = this.$route.params.from_store_id
        ? this.$route.params.from_store_id
        : null;
      this.$router.push({
        name: this.onlineStoreView,
        params: {
          store_id: storeId,
          from_store_id: fromStoreId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -3rem;
}
.establishment-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  font-weight: 600;
}
.settings-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.stores-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-row: 1fr;

  .store-name {
    font-size: 1.1rem;
  }

  .store-button {
    font-size: 1rem;
    padding: 10px 0;
  }

  .store-card {
    position: relative;
    cursor: pointer;
    padding: 5px;
  }

  .store-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  @media (min-width: 300px) {
    grid-template-columns: repeat(2, 1fr);

    .store-name {
      font-size: 1.1rem;
    }

    .store-button {
      font-size: 1rem;
    }
  }

  @media (min-width: 440px) {
    grid-template-columns: repeat(3, 1fr);

    .store-name {
      font-size: 1.1rem;
    }

    .store-button {
      font-size: 1rem;
    }
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(4, 1fr);

    .store-name {
      font-size: 1.1rem;
    }

    .store-button {
      font-size: 1rem;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);

    .store-name {
      font-size: 1.2rem;
    }

    .store-button {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);

    .store-name {
      font-size: 1.2rem;
    }

    .store-button {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);

    .store-name {
      font-size: 1.2rem;
    }

    .store-button {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
    .store-name {
      font-size: 1.2rem;
    }

    .store-button {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 1836px) {
    grid-template-columns: repeat(7, 1fr);

    .store-name {
      font-size: 1.2rem;
    }

    .store-button {
      font-size: 1rem;
    }
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(8, 1fr);

    .store-name {
      font-size: 1.2rem;
    }

    .store-button {
      font-size: 1rem;
    }
  }
}
</style>
